document.addEventListener('livewire:initialized', () => {
  const taskId = (new URL(window.location.href).searchParams.get('showTask'));
  if (taskId && parseInt(taskId) > 0) {
    Livewire.dispatch('openModal', { component: 'tasks.detail-modal', arguments: { taskId: taskId } });
  }
});

Livewire.on('taskOpened', (taskId => {
  const url = new URL(window.location.href);
  url.searchParams.set('showTask', taskId);
  history.pushState({}, '', url);
}));